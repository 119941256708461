import React from "react"
import Button from "src/component/common/button"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { ImgixGatsbyImage } from '@imgix/gatsby';
import { useStaticQuery, graphql } from 'gatsby'

export default function Footer({ data }) {
    data = useStaticQuery(
        graphql`
            query {
                allMicrocmsNews(
                    limit: 3
                    sort: {fields: display_date, order: DESC}
                    filter: {post_category: {elemMatch: {id: {eq: "case_study"}}}}
                ) {
                    edges {
                        node {
                            publishedAt(formatString: "YYYY-MM-DD")
                            display_date(formatString: "YYYY-MM-DD")
                            id
                            newsId
                            title
                            case_study_name
                            case_study_thumbnail {
                                width
                                url
                                height
                            }
                            type_category {
                                id
                                name
                            }
                            uses_category {
                                id
                                name
                            }
                            casestudy_category {
                                id
                                name
                            }
                        }
                    }
                }
            }
        `
    )
    return (
        <>
            <ol className="c-casestudy-list-latest-3posts">
                {data.allMicrocmsNews.edges.map(({ node }) => (
                    <li key={node.id}>
                        <Link to={`/news/${node.newsId}`}>
                            {
                                node.case_study_thumbnail ? (
                                    <div className="c-casestudy-list-latest-3posts__image">
                                        <ImgixGatsbyImage
                                            src={node.case_study_thumbnail.url}
                                            imgixParams={{ auto: ['format', 'compress'] }}
                                            layout="fullWidth"
                                            style={{ height: "100%" }}
                                            alt={node.title}
                                            sourceWidth={node.case_study_thumbnail.width}
                                            sourceHeight={node.case_study_thumbnail.height}
                                        />
                                    </div>
                                ) : (
                                    <div className="c-casestudy-list-latest-3posts__image is-default">
                                        <StaticImage
                                            src="../../assets/img/pages/top/casestudy_img01.png"
                                            alt={node.title}
                                            placeholder="blurred"
                                        />
                                    </div>
                                )
                            }
                            <div className="c-casestudy-list-latest-3posts__text">
                                {
                                    node.case_study_name ? (
                                        <p className="c-casestudy-list-latest-3posts__company">{node.case_study_name}</p>
                                    ) : null
                                }
                                <h3 className="c-casestudy-list-latest-3posts__title">{node.title}</h3>
                                {
                                    (node.type_category || node.uses_category || node.casestudy_category) ? (
                                        <ul className="c-casestudy-list-latest-3posts__tag">
                                            {
                                                node.type_category ? (
                                                    node.type_category.map((category) => (
                                                        <li key={category.id}>{category.name}</li>
                                                    ))
                                                ) : null
                                            }
                                            {
                                                node.uses_category ? (
                                                    node.uses_category.map((category) => (
                                                        <li key={category.id}>{category.name}</li>
                                                    ))
                                                ) : null
                                            }
                                            {
                                                node.casestudy_category ? (
                                                    node.casestudy_category.map((category) => (
                                                        <li key={category.id}>{category.name}</li>
                                                    ))
                                                ) : null
                                            }
                                        </ul>
                                    ) : null
                                }
                                <div className="c-casestudy-list-latest-3posts__button">
                                    <Button className="c-button01" label="詳しく見る" />
                                </div>
                            </div>
                        </Link>
                    </li>
                ))}
            </ol>
        </>
    )
}
