import React from "react"
import { ImgixGatsbyImage } from '@imgix/gatsby';
import { useStaticQuery, graphql } from 'gatsby'

export default function Footer({ data }) {
    data = useStaticQuery(
        graphql`
            query {
                allMicrocmsClient(limit: 12) {
                    edges {
                        node {
                            id
                            logo {
                                url
                                width
                                height
                            }
                            name
                        }
                    }
                }
            }
        `
    )
    return (
        <>
            <ul className="c-client-list">
                {data.allMicrocmsClient.edges.map(({ node }) => (
                    <li key={node.id}>
                        <ImgixGatsbyImage
                            src={node.logo.url}
                            imgixParams={{ auto: ['format', 'compress'] }}
                            layout="fullWidth"
                            style={{ height: "100%" }}
                            alt={node.name}
                            sourceWidth={node.logo.width}
                            sourceHeight={node.logo.height}
                        />
                    </li>
                ))}
            </ul>
        </>
    )
}
