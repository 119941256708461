import React from "react"
import Layout from "src/component/layout/layout"
import Button from "src/component/common/button"
import CasestudyListLatest3posts from "src/component/list/casestudyListLatest3posts"
import NewsListLatest1posts from "src/component/list/newsListLatest1posts"
import NewsListLatest3posts from "src/component/list/newsListLatest3posts"
import ClientListLatest12posts from "src/component/list/clientListLatest12posts"
import Slider from "src/component/common/slider"
import FormTop from "src/component/common/formTop"
import { Seo } from "src/component/common/seo"
import { StaticImage, GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import { graphql } from 'gatsby'

export default function Home({ data }) {
    const about01ImgPath = withArtDirection(getImage(data.about01Pc.childImageSharp), [
        {
            media: "(max-width: 768px)",
            image: getImage(data.about01Sp.childImageSharp),
        },
    ])
    const about02ImgPath = withArtDirection(getImage(data.about02Pc.childImageSharp), [
        {
            media: "(max-width: 768px)",
            image: getImage(data.about02Sp.childImageSharp),
        },
    ])
    const productAirlakeImgPath = withArtDirection(getImage(data.productAirlakePc.childImageSharp), [
        {
            media: "(max-width: 768px)",
            image: getImage(data.productAirlakeSp.childImageSharp),
        },
    ])
    const productBecozImgPath = getImage(data.productBecozPc.childImageSharp)
    const productPerswellImgPath = withArtDirection(getImage(data.productPerswellPc.childImageSharp), [
        {
            media: "(max-width: 768px)",
            image: getImage(data.productPerswellSp.childImageSharp),
        },
    ])
    const productTownearImgPath = withArtDirection(getImage(data.productTownearPc.childImageSharp), [
        {
            media: "(max-width: 768px)",
            image: getImage(data.productTownearSp.childImageSharp),
        },
    ])
    React.useEffect(() => {
        let js_scroll = document.getElementsByClassName('js-scroll');
        const scroll = () => {
            for (let i = 0; i < js_scroll.length; i++) {
                if ((window.pageYOffset + (window.innerHeight / 1.5)) > (window.pageYOffset + js_scroll[i].getBoundingClientRect().top)) {
                    js_scroll[i].classList.add('is-active');
                }
            }
        };
        scroll();
        window.addEventListener('scroll', () => {
            scroll();
        })
    }, [])
    return (
        <>
            <Layout isTop>
                <div className="p-keyvisual">
                    <div className="p-keyvisual__inner c-inner-primary">
                        <div className="p-keyvisual__content">
                            <div className="p-keyvisual__background">
                                <Slider />
                            </div>
                            <div className="p-keyvisual__title js-scroll">
                                <div className="is-01"><p><span>データを商いに。</span></p></div>
                                <div className="is-02"><p><span>Data Science for every business.</span></p></div>
                                <div className="is-03"><p><span>データを余すことなく活用し、<br />企業と社会に新しい価値をもたらす<br className="c-sp" />ビジネスパートナー</span></p></div>
                            </div>
                            <FormTop />
                            <div className="p-keyvisual__news">
                                <NewsListLatest1posts />
                                <div className="p-keyvisual__news__button c-pc"><Button path="/search" className="c-button01 is-white" label="新着一覧を見る" /></div>
                                <div className="p-keyvisual__news__button c-sp"><Button path="/search" className="c-button01" label="新着一覧を見る" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="p-top-about01">
                    <div className="p-top-about01__background">
                        <StaticImage
                            src="../assets/img/pages/top/about01_bg01.png"
                            alt=""
                            placeholder="blurred"
                        />
                    </div>
                    <div className="p-top-about01__inner c-inner-primary">
                        <div className="p-top-about01__image">
                            <GatsbyImage
                                image={about01ImgPath}
                                className="is-gatsby"
                                alt="特徴のイメージその1"
                            />
                        </div>
                        <div className="p-top-about01__text">
                            <h2 className="p-top-about01__title js-scroll">
                                <div><div><span>形式や種類にとらわれず、</span></div></div>
                                <div><div><span>全てのデータを価値に変える</span></div></div>
                            </h2>
                            <p className="p-top-about01__lead">画像や動画、音声、テキストなど、<br />企業内のデータの約8割を占めるとされる「非構造化データ」。<br />データサイエンティストの高度な知識がなければ<br />処理できなかったあらゆるデータを<br />より簡単に、より有効に活用できる世界へ。</p>
                            <div className="p-top-about01__button">
                                <Button path="/company" className="c-button01 is-white" label="DATAFLUCTとは" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="p-top-about02">
                    <div className="p-top-about02__background">
                        <StaticImage
                            src="../assets/img/pages/top/about02_bg01.png"
                            alt=""
                            placeholder="blurred"
                        />
                    </div>
                    <div className="p-top-about02__inner c-inner-primary">
                        <div className="p-top-about02__text">
                            <h2 className="p-top-about02__title js-scroll">
                                <div><div><span>データ活用に必要なすべてを</span></div></div>
                                <div><div><span>一気通貫で提供</span></div></div>
                                <div><div><span>高速・高精度な開発を</span></div></div>
                                <div><div><span>低コストで実現</span></div></div>
                            </h2>
                            <p className="p-top-about02__lead">分断されていた「DX」や「データ活用プロジェクト」の工程の全てを、DATAFLUCTがパートナーとして伴走します。<br />あらゆるデータを組み合わせて価値を生み出す「マルチモーダルデータ活用」を高速・高精度に実現。</p>
                            <div className="p-top-about02__button">
                                <Button path="/contact" className="c-button02 is-small" label="相談する" />
                            </div>
                        </div>
                        <div className="p-top-about02__image">
                            <GatsbyImage
                                image={about02ImgPath}
                                className="is-gatsby"
                                alt="特徴のイメージその2"
                            />
                        </div>
                    </div>
                </section>
                <section className="p-top-casestudy">
                    <div className="p-top-casestudy__inner c-inner-primary">
                        <div className="p-top-casestudy__title c-title01">
                            <h2 className="p-top-casestudy__title__text c-title01__text">Case study</h2>
                            <Button path="/search/?pc=case_study" className="p-top-casestudy__title__button c-title01__button c-button01" label="すべての事例を見る" />
                        </div>
                        <CasestudyListLatest3posts />
                    </div>
                </section>
                <section className="p-top-client">
                    <div className="p-top-client__inner c-inner-primary">
                        <div className="p-top-client__title c-title01">
                            <h2 className="p-top-client__title__text c-title01__text">Customers</h2>
                            <Button path="/company#client" className="p-top-client__title__button c-title01__button c-button01" label="取引実績一覧を見る" />
                            <small className="p-top-client__title__annotation c-title01__annotation">※ 順不同</small>
                        </div>
                        <ClientListLatest12posts />
                    </div>
                </section>
                <section className="p-top-product">
                    <div className="p-top-product__inner c-inner-primary">
                        <h2 className="p-top-product__title">PRODUCTS</h2>
                        <section className="p-top-product__item">
                            <div className="p-top-product__item__catchcopy c-pc js-scroll">
                                <div><div><span>社内外に眠る、あらゆるデータを価値に変える</span></div></div>
                            </div>
                            <div className="p-top-product__item__catchcopy c-sp js-scroll">
                                <div><div><span>社内外に眠る、</span></div></div>
                                <div><div><span>あらゆるデータを価値に変える</span></div></div>
                            </div>
                            <div className="p-top-product__item__content is-text-left">
                                <div className="p-top-product__item__text">
                                    <h3 className="p-top-product__item__subtitle">
                                        <span className="is-airlake">
                                            <StaticImage
                                                src="../assets/img/pages/top/logo_airlake.png"
                                                alt="AirLake"
                                                placeholder="blurred"
                                            />
                                        </span>
                                    </h3>
                                    <p className="p-top-product__item__lead">AirLake（エアーレイク）は非構造化データを誰でも簡単に使えるデータに変換できるクラウド型のデータプラットフォームです。</p>
                                    <p className="p-top-product__item__lead">動画・画像・音声など多種多様な形式の非構造化データを活用するには、データサイエンティストによる前処理や構造化変換が必要となり、コストと時間がかかります。簡単な操作だけで社内外の様々なデータを収集・分析しやすい形に加工・蓄積し、新たなインサイトの理解や課題の解決に役立てられます。</p>
                                    <div className="p-top-product__item__button">
                                        <Button external path="https://service.datafluct.com/airlake" className="c-button01" label="詳しく見る" />
                                    </div>
                                </div>
                                <div className="p-top-product__item__image">
                                    <GatsbyImage
                                        image={productAirlakeImgPath}
                                        className="is-airlake"
                                        alt="AirLake（エアーレイク）の機能のイメージ"
                                    />
                                </div>
                            </div>
                        </section>
                        <section className="p-top-product__item">
                            <div className="p-top-product__item__catchcopy js-scroll">
                                <div><div><span>データサイエンスの力で</span></div></div>
                                <div><div><span>カーボンニュートラルな未来を実現</span></div></div>
                            </div>
                            <div className="p-top-product__item__content is-text-right">
                                <div className="p-top-product__item__text">
                                    <h3 className="p-top-product__item__subtitle">
                                        <span className="is-becoz">
                                            <StaticImage
                                                src="../assets/img/pages/top/logo_becoz.png"
                                                alt="becoz"
                                                placeholder="blurred"
                                            />
                                        </span>
                                    </h3>
                                    <p className="p-top-product__item__lead">becoz（ビコーズ）はデータサイエンスの力でモノやサービスの環境価値を見える化し、生活者を含むあらゆるステークホルダーのカーボンニュートラルに向けた行動を促進するプラットフォームです。</p>
                                    <div className="p-top-product__item__button">
                                        <Button external path="https://becoz.ai/" className="c-button01" label="詳しく見る" />
                                    </div>
                                </div>
                                <div className="p-top-product__item__image">
                                    <GatsbyImage
                                        image={productBecozImgPath}
                                        className="is-becoz"
                                        alt="becoz（ビコーズ）の機能のイメージ"
                                    />
                                </div>
                            </div>
                        </section>
                        <section className="p-top-product__item">
                            <div className="p-top-product__item__catchcopy c-pc js-scroll">
                                <div><div><span>機械学習と外部データを組み合わせた自動需要予測で、</span></div></div>
                                <div><div><span>最適な仕入れ・生産を実現</span></div></div>
                            </div>
                            <div className="p-top-product__item__catchcopy c-sp js-scroll">
                                <div><div><span>機械学習と外部データを</span></div></div>
                                <div><div><span>組み合わせた自動需要予測で、</span></div></div>
                                <div><div><span>最適な仕入れ・生産を実現</span></div></div>
                            </div>
                            <div className="p-top-product__item__content is-text-left">
                                <div className="p-top-product__item__text">
                                    <h3 className="p-top-product__item__subtitle">
                                        <span className="is-perswell">
                                            <StaticImage
                                                src="../assets/img/pages/top/logo_perswell.png"
                                                alt="Perswell"
                                                placeholder="blurred"
                                            />
                                        </span>
                                    </h3>
                                    <p className="p-top-product__item__lead">Perswell（パースウェル）は、機械学習と外部データを活用した自動需要予測・サプライチェーンマネジメントサービスです。<br />弊社のデータサイエンティストが適切な外部データ（天気データ等）も活用しながら、機械学習による需要予測モデルを構築します。<br />担当者の勘や経験に頼らず、商品別に最適な発注が可能となり、欠品・余剰や在庫量を削減いたします。</p>
                                    <div className="p-top-product__item__button">
                                        <Button external path="https://service.datafluct.com/perswell" className="c-button01" label="詳しく見る" />
                                    </div>
                                </div>
                                <div className="p-top-product__item__image">
                                    <GatsbyImage
                                        image={productPerswellImgPath}
                                        className="is-perswell"
                                        alt="Perswell（パースウェル）の機能のイメージ"
                                    />
                                </div>
                            </div>
                        </section>
                        <section className="p-top-product__item">
                            <div className="p-top-product__item__catchcopy js-scroll">
                                <div><div><span>持続可能なまちづくりを助ける</span></div></div>
                                <div><div><span>地理空間情報プラットフォーム</span></div></div>
                            </div>
                            <div className="p-top-product__item__content is-text-right">
                                <div className="p-top-product__item__text">
                                    <h3 className="p-top-product__item__subtitle">
                                        <span className="is-townear">
                                            <StaticImage
                                                src="../assets/img/pages/top/logo_townear.png"
                                                alt="TOWNEAR"
                                                placeholder="blurred"
                                            />
                                        </span>
                                    </h3>
                                    <p className="p-top-product__item__lead">TOWNEAR（タウニア）はまちや都市に関するあらゆるデータを、誰もが活用して、持続可能なまちづくりを推進するEBPM・地理情報プラットフォームです。</p>
                                    <p className="p-top-product__item__lead">まちを構成する複雑なデータ、人流・消費・行動・CO2データなどの 「まち」に関するビッグデータを収集し読み解き、社会課題を解決するヒントを導き出します。データを扱いやすい形に整え、データサイエンスによってより良い選択を促す行動変容モデルや、より細やかなパーソナライゼーションを実現します。</p>
                                    <div className="p-top-product__item__button">
                                        <Button external path="https://lp.townear.ai/" className="c-button01" label="詳しく見る" />
                                    </div>
                                </div>
                                <div className="p-top-product__item__image">
                                    <GatsbyImage
                                        image={productTownearImgPath}
                                        className="is-townear"
                                        alt="TOWNEAR（タウニア）の機能のイメージ"
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <section className="p-top-news">
                    <div className="p-top-news__inner c-inner-primary">
                        <div className="p-top-news__title c-title01">
                            <h2 className="p-top-news__title__text c-title01__text">Latest updates</h2>
                            <Button path="/search" className="p-top-news__title__button c-title01__button c-button01 latest-updates-button" label="新着一覧を見る" />
                        </div>
                        <NewsListLatest3posts />
                    </div>
                </section>
            </Layout>
        </>
    )
}

export function Head({ data }) {
    const schema = {
        "@context": "http://schema.org",
        "@type": "WebSite",
        "url": data.site.siteMetadata.productionSiteUrl,
        "name": data.microcmsSetting.site_title,
        "publisher": {
            "@type": "Organization",
            "name": data.microcmsSetting.site_title,
            "url": data.site.siteMetadata.productionSiteUrl,
            "logo": {
                "@type": "ImageObject",
                "url": data.site.siteMetadata.productionSiteUrl + "img/common/microdata_logo.png"
            },
            "address": {
                "@type": "PostalAddress",
                "postalCode": "150-0043",
                "addressRegion": "東京都",
                "addressLocality": "渋谷区",
                "streetAddress": "道玄坂一丁目19番9号 第一暁ビル6階"
            }
        },
        "image": {
            "@type": "ImageObject",
            "url": data.site.siteMetadata.productionSiteUrl + "img/common/microdata_logo.png"
        },
        "mainEntity": {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": data.site.siteMetadata.productionSiteUrl,
                        "name": "HOME"
                    }
                }
            ]
        }
    };
    const schemaAsString = JSON.stringify(schema, null, 2);
    return (
        <Seo
            title=""
            description=""
            pathname=""
        >
            <script type="application/ld+json">{schemaAsString}</script>
        </Seo>
    )
}

export const query = graphql`
    query {
        site {
            siteMetadata {
                productionSiteUrl
            }
        }
        microcmsSetting {
            site_title
        }
        about01Pc: file(relativePath: {eq: "pages/top/about01_img01.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        about01Sp: file(relativePath: {eq: "pages/top/about01_img01-sp.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        about02Pc: file(relativePath: {eq: "pages/top/about02_img01.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        about02Sp: file(relativePath: {eq: "pages/top/about02_img01-sp.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        productAirlakePc: file(relativePath: {eq: "pages/top/product_airlake_img.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        productAirlakeSp: file(relativePath: {eq: "pages/top/product_airlake_img-sp.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        productBecozPc: file(relativePath: {eq: "pages/top/product_becoz_img.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        productBecozSp: file(relativePath: {eq: "pages/top/product_becoz_img-sp.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        productPerswellPc: file(relativePath: {eq: "pages/top/product_perswell_img.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        productPerswellSp: file(relativePath: {eq: "pages/top/product_perswell_img-sp.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        productTownearPc: file(relativePath: {eq: "pages/top/product_townear_img.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        productTownearSp: file(relativePath: {eq: "pages/top/product_townear_img-sp.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
    }
`
