import React from "react"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from 'gatsby'

export default function FormTop({ data }) {
    const url = useLocation()
    const searchByRecommend = (e) => {
        window.location.href = `${url.href}search/?q=${e.currentTarget.getAttribute('data-query')}`;
        // navigate(`/search/?q=${e.currentTarget.getAttribute('data-query')}`)
    };
    data = useStaticQuery(
        graphql`
            query {
                microcmsSetting {
                    top_keywords {
                        keyword
                    }
                }
            }
        `
    )
    return (
        <>
            <form method="GET" action="/search" className="p-keyvisual__search">
                <div className="p-keyvisual__search__form">
                    <input className="p-keyvisual__search__form__input" type="text" id="q" name="q" placeholder="検索したいワードを入力してください" />
                    <button className="p-keyvisual__search__form__button" type="submit" aria-label="記事の検索">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 0C3.14585 0 0 3.14585 0 7C0 10.8541 3.14585 14 7 14C8.748 14 10.345 13.348 11.5742 12.2812L12 12.707V14L18 20L20 18L14 12H12.707L12.2812 11.5742C13.348 10.345 14 8.748 14 7C14 3.14585 10.8541 0 7 0ZM7 2C9.77327 2 12 4.22673 12 7C12 9.77327 9.77327 12 7 12C4.22673 12 2 9.77327 2 7C2 4.22673 4.22673 2 7 2Z" fill="#FBFDFF"/>
                        </svg>
                    </button>
                </div>
                <ul className="p-keyvisual__search__recommend">
                    {data.microcmsSetting.top_keywords.map(({ keyword }) => (
                        <li
                            key={keyword}
                            data-query={keyword}
                        ><button onClick={searchByRecommend}>{keyword}</button></li>
                    ))}
                    {/*
                    <li onClick={searchByRecommend} data-query="データ解析"><span>データ解析</span></li>
                    <li onClick={searchByRecommend} data-query="ダイナミックプライシング"><span>ダイナミックプライシング</span></li>
                    <li onClick={searchByRecommend} data-query="AI活用"><span>AI活用</span></li>
                    <li onClick={searchByRecommend} data-query="需要予測"><span>需要予測</span></li>
                    */}
                </ul>
            </form>
        </>
    )
}