import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from 'gatsby'

export default function Footer({ data }) {
    data = useStaticQuery(
        graphql`
            query {
                allMicrocmsNews(
                    limit: 1
                    sort: {fields: display_date, order: DESC}
                ) {
                    edges {
                        node {
                            publishedAt(formatString: "YYYY-MM-DD")
                            display_date(formatString: "YYYY-MM-DD")
                            id
                            newsId
                            post_category {
                                id
                                name
                            }
                            title
                        }
                    }
                }
            }
        `
    )
    return (
        <>
            <ol className="c-news-list-latest-1posts">
                {data.allMicrocmsNews.edges.map(({ node }) => (
                    <li key={node.id}>
                        <Link to={`/news/${node.newsId}`}>
                            <div className="c-news-list-latest-1posts__information">
                                {
                                    node.display_date ? (
                                        <time className="c-news-list-latest-1posts__time" dateTime={node.display_date}>{node.display_date.replace(/-/g, '.')}</time>
                                    ) : (
                                        <time className="c-news-list-latest-1posts__time" dateTime={node.publishedAt}>{node.publishedAt.replace(/-/g, '.')}</time>
                                    )
                                }
                                {
                                    node.post_category ? (
                                        <ul className="c-news-list-latest-1posts__category">
                                            { node.post_category.map((category) => (
                                                <li key={category.id}>{category.name}</li>
                                            ))}
                                        </ul>
                                    ) : null
                                }
                            </div>
                            <p className="c-news-list-latest-1posts__title">{node.title}</p>
                        </Link>
                    </li>
                ))}
            </ol>
        </>
    )
}
